<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h6
          v-if="playerDetail.firstname || playerDetail.lastname"
          class="mt-0 mb-0 my-2"
        >
          <b-avatar
            variant="secondary"
            size="sm"
            class="mr-1"
          ></b-avatar>
          {{playerDetail.firstname}}
          {{playerDetail.lastname}}
          <b-link :to="`/players/detail/${playerId}`">
            ({{playerDetail.username}})
          </b-link>
        </h6>

        <h6>
          {{ `${$t('fields.result')} (${history.length})`}}
        </h6>

      </div>

      <div class="col-3 d-inline-flex align-items-center">
        <multiselect
          v-model="filterLogType"
          class="w-100"
          :options="['clear', 'deposit', 'withdraw', 'direct_deposit', 'direct_withdraw', 'bet', 'win', 'draw', 'cancel_bet']"
          :searchable="false"
          :close-on-select="false"
          select-label=""
          placeholder="filter by ...."
          :custom-label="
            (item) =>
              `${$t('credits.' + item)}`
          "
          :multiple="true"
        >

          <template
            slot="selection"
            slot-scope="{ values, isOpen }"
          >
            <span
              v-if="values.length && !isOpen"
              class="multiselect__single"
            >( {{ values.length }} ) filters selected</span>
          </template>
          <template
            slot="tag"
            slot-scope=""
          ></template>
          <template slot="noResult">
            <span>ไม่มีข้อมูล</span>
          </template>
        </multiselect>

        <!-- <b-dropdown variant="light" class="ml-md-2 w-100" dropleft>
            <template slot="button-content">
              <i class="uil uil-filter"></i>
              <span>
                {{ filterLogType === 'all' ? "ทั้งหมด" : $t(`credits.${filterLogType}`) }}
              </span>
              <i class="uil uil-angle-down"></i>
            </template>

            <b-dropdown-item-button @click="filterLogType ='all'">
              {{ $t('fields.all') }}
            </b-dropdown-item-button>

            <b-dropdown-item-button @click="filterLogType = 'deposit'" >
              {{ $t('credits.deposit') }}
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="filterLogType = 'withdraw'">
              {{ $t('credits.withdraw') }}
            </b-dropdown-item-button>

            <b-dropdown-item-button @click="filterLogType = 'direct_deposit'" >
              {{ $t('credits.direct_deposit') }}
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="filterLogType = 'direct_withdraw'">
              {{ $t('credits.direct_withdraw') }}
            </b-dropdown-item-button>

            <b-dropdown-item-button @click="filterLogType = 'bet'" >
              {{ $t('credits.bet') }}
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="filterLogType = 'win'">
              {{ $t('credits.win') }}
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="filterLogType = 'draw'">
              {{ $t('credits.draw') }}
            </b-dropdown-item-button>    
            <b-dropdown-item-button @click="filterLogType = 'cancel_bet'">
              {{ $t('credits.cancel_bet') }}
            </b-dropdown-item-button>            
          </b-dropdown> -->
      </div>

    </div>
    <!-- Table -->
    <b-table
      :items="history"
      :fields="pendingFields"
      :busy="credit.isFetchingCreditHistoryByPlayerId"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(perform_by)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(username)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(type)="data">
        <b-badge
          v-if="data.value === 'deposit' || data.value === 'direct_deposit' || data.value === 'win' || data.value === 'cancel_bet' "
          class="badge-soft-success"
        >
          {{ $t(`credits.${data.value}`) }}
        </b-badge>
        <b-badge
          v-if="data.value === 'withdraw' || data.value === 'withdraw_request'|| data.value === 'direct_withdraw' || data.value === 'bet' "
          class="badge-soft-danger"
        >
          {{ $t(`credits.${data.value}`) }}
        </b-badge>
      </template>
      <template #cell(amount)="data">
        <span
          v-if="data.item.type === 'deposit' || data.item.type === 'direct_deposit' || data.item.type === 'win' || data.item.type === 'cancel_bet' "
          class="text-success"
        >
          {{ data.value  }}
        </span>
        <span
          v-if="data.item.type === 'withdraw' || data.item.type === 'withdraw_request' || data.item.type === 'direct_withdraw' || data.item.type === 'bet' "
          class="text-danger"
        >
          - {{ data.value  }}
        </span>
      </template>
      <template #cell(before_amount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(after_amount)="data">
        {{ data.value | currency }}
      </template>
      <!-- <template #cell(status)="data">
        <b-badge
          v-if="data.value === 'success'"
          variant="success"
        >
          {{ $t('utils.status.success_credit')}}
        </b-badge>
        <b-badge
          v-else-if="data.value === 'waiting'"
          variant="info"
        >
          {{ $t('utils.status.waiting')}}
        </b-badge>
        <b-badge
          v-else-if="data.value === 'processing'"
          variant="warning"
        >
          {{ $t('utils.status.processing')}}
        </b-badge>
        <b-badge
          v-else
          variant="danger"
        >
          {{ $t('utils.status.unsuccessful')}}
        </b-badge>

      </template> -->
      <template #cell(detail)="data">
        {{ data.value || '-'}}
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
      </template>
    </b-table>
    <!-- pagination -->
    <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  page: {
    title: 'Player Transactions',
  },
  data() {
    return {
      filterLogType: [],
      currentPage: 1,
      limit: 50,
      offset: 0,
      filter: '',
      pendingFields: [
        '#',
        {
          key: 'created_at',
          label: `${this.$t('fields.issued_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'perform_by',
          label: `${this.$t('credits.operated_by')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'username',
          label: this.$t('credits.receiver'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'type',
          label: this.$t('credits.type'),
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'before_amount',
          label: this.$t('credits.before_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'after_amount',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        // {
        //   key: 'status',
        //   label: this.$t('fields.status'),
        //   class: 'text-center',
        //   thStyle: {
        //     minWidth: '100px',
        //   },
        // },
        {
          key: 'detail',
          label: this.$t('fields.remark'),
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState(['player', 'credit']),
    ...mapGetters(['playerDetail', 'playerCreditHistory']),
    playerId() {
      return this.$route.params.id
    },
    history() {
      return this.playerCreditHistory.data || []
    },
    totalRow() {
      return this.playerCreditHistory.total || 0
    },
  },
  watch: {
    currentPage(val) {
      const nextPage = val - 1
      const nextVal = nextPage * this.limit
      this.offset = nextVal
      this.fetchData()
    },
    filter(val) {
      if (val) {
        this.fetchDataByFilter(val)
      }
    },
    filterLogType(val) {
      if (val) {
        if (val?.includes('clear')) {
          this.filterLogType = []
          return
        }
        this.fetchData()
      }
    },
  },
  created() {
    if (this.playerId) {
      this.fetchPlayer(this.playerId)
      this.fetchData()
    }
  },
  methods: {
    ...mapActions(['fetchPlayer', 'fetchCreditHistoryByPlayerId']),
    fetchData() {
      this.fetchCreditHistoryByPlayerId({
        playerId: this.playerId,
        limit: this.limit,
        offset: this.offset,
        type: this.filterLogType?.join(','),
      })
    },
    fetchDataByFilter() {},
  },
}
</script>